import React, { useState, Fragment } from "react";
import { Tooltip } from "react-tooltip";
import CheckMateImg from "@/assets/images/chess/checkmate.png";
import BottomDialog from "@/components/BottomDialog";
import { CHESS_PIECE_ICONS } from "@/constants/chessPieceIcons";
import Image from "next/image";
import { useRouter } from "next/router";
import { DEFAULT_POPUP_DATA, TOURNAMENT_POPUP_DATA } from "./constants";
import ROUTES from "@/constants/routes";

const HowToPlay = ({
    children,
    isGameOver = true,
    isTournamentPage = false,
}: {
    children?: React.ReactNode;
    isGameOver?: boolean;
    isTournamentPage?: boolean;
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const { push } = useRouter();

    const handlePopup = () => {
        setIsOpen((prev) => !prev);
    };

    const POPUP_DATA = isTournamentPage
        ? TOURNAMENT_POPUP_DATA
        : DEFAULT_POPUP_DATA;

    return (
        <Fragment>
            <div
                className="w-full"
                data-testid={"how-to-play-btn"}
                onClick={handlePopup}
            >
                {children}
            </div>
            <BottomDialog
                open={isOpen}
                setIsOpen={setIsOpen}
                onClose={handlePopup}
                dialogContentClassName={"!px-5 !pt-8 !pb-15"}
            >
                <div className="flex flex-col">
                    <p className="text-center font-extrabold text-gray-5 font-basement-grotesque text-base">
                        {isTournamentPage ? "Daily League | How to play" : "How to play"}
                    </p>
                    <div className="mt-3 flex w-full flex-col gap-y-2 bg-dark-blue-1 p-4">
                        <div className="flex flex-col gap-y-3">
                            {POPUP_DATA.STEPS.map(({ id, title, subtitle }) => (
                                <div
                                    className="flex items-center justify-start gap-x-4 xxs:gap-x-2 xs:gap-x-4"
                                    key={id}
                                >
                                    <div className="w-1/8 flex h-9 w-9 items-center justify-center rounded-[10px] border border-dark-blue xxs:h-8 xxs:w-8 xs:h-10 xs:w-10">
                                        <span className="h-fit w-full text-center font-extrabold text-dark-blue font-basement-grotesque text-xl xxs:text-lg xs:text-xl">
                                            {id}
                                        </span>
                                    </div>
                                    <div className="w-7/8 flex flex-col">
                                        <h2 className="font-medium text-gray-4 font-poppins text-sm">
                                            {title}
                                        </h2>
                                        <h4 className="font-normal text-gray-5 font-poppins text-xs">
                                            {subtitle}
                                        </h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="mx-auto flex w-full max-w-80/100 flex-col items-center gap-y-1 xxs:max-w-full xs:max-w-80/100">
                            <div className="mb-2 mt-3 h-full w-full border-[0.5px] border-dashed border-[#8C8C8C] " />
                            <h2 className="font-medium text-gray-5 underline font-poppins text-xs">
                                {isTournamentPage
                                    ? "Prize Distribution"
                                    : "Points Breakdown"}
                            </h2>
                            <div className="mx-auto mt-2 flex w-3/4 flex-col gap-y-1">
                                {POPUP_DATA.POINTS.map(
                                    ({ id, point, piece }) => (
                                        <div
                                            key={id}
                                            className="flex w-full items-center justify-between rounded-md"
                                        >
                                            <div className="flex w-1/2 items-center gap-x-2">
                                                {!isTournamentPage && (
                                                    <Image
                                                        src={
                                                            piece ===
                                                            "Checkmate"
                                                                ? CheckMateImg
                                                                : CHESS_PIECE_ICONS[
                                                                      piece.toLowerCase()
                                                                  ]?.["black"]
                                                        }
                                                        alt=""
                                                        width={18}
                                                        height={18}
                                                    />
                                                )}
                                                <p className="text-left font-light text-gray-5 font-poppins text-xs">
                                                    {piece}
                                                </p>
                                            </div>
                                            <div className="flex w-[45%] items-center justify-between">
                                                <span className="font-light text-gray-5 font-poppins text-xs">
                                                    :
                                                </span>
                                                <p className="text-right font-light text-gray-5 font-poppins text-xs">
                                                    {point}
                                                </p>
                                            </div>
                                        </div>
                                    )
                                )}
                                {isTournamentPage && (
                                    <p className="text-left font-light text-gray-5 font-poppins text-xs">
                                        and many more...
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 flex w-full flex-col items-center justify-center gap-y-4">
                        <button
                            className="h-10 w-full cursor-pointer rounded-md bg-dark-pink-1 text-center font-extrabold text-gray-4 font-basement-grotesque text-sm"
                            type="submit"
                            onClick={handlePopup}
                        >
                            Understood
                        </button>
                        {!isGameOver && (
                            <Tooltip
                                anchorSelect={`.know-more-btn`}
                                content={
                                    "Please finish the live game to go to this page"
                                }
                            />
                        )}
                        <p
                            className={`know-more-btn text-center font-extrabold text-gray-5 underline font-basement-grotesque text-sm ${
                                isGameOver ? "cursor-pointer" : "opacity-50"
                            }`}
                            onClick={() => {
                                if (isGameOver) push(ROUTES.rules());

                                return;
                            }}
                        >
                            Know more
                        </p>
                    </div>
                </div>
            </BottomDialog>
        </Fragment>
    );
};

export default HowToPlay;
