import React from "react";
import DefaultImg from "@/assets/images/avatar/default.png";
import HamburgerMenuImg from "@/assets/images/icons/hamburger-menu.png";
import LogoutImg from "@/assets/images/icons/menu/logout.png";
import HowToPlayImg from "@/assets/images/icons/menu/question-mark.png";
import TcImg from "@/assets/images/icons/menu/t-c.png";
import WalletImg from "@/assets/images/icons/menu/wallet.png";
import { useCurrentUser } from "@/contexts/currentUser";
import * as Popover from "@radix-ui/react-popover";
import Image, { StaticImageData } from "next/image";
import editImg from "@/assets/images/icons/menu/edit.svg";
import { useRouter } from "next/router";
import { signOut } from "supertokens-web-js/recipe/passwordless";
import HowToPlay from "../HowToPlay";
import EditName from "../EditName";

type SubMenuProps = {
    text: string;
    onClick?: () => void;
    img: StaticImageData;
};

const SubMenu = ({ text, onClick, img }: SubMenuProps) => {
    return (
        <div
            className="group flex w-full cursor-pointer items-center gap-x-2 bg-transparent"
            onClick={onClick}
        >
            <Image
                src={img}
                alt="text"
                width={20}
                height={20}
                draggable={false}
                className="h-4 w-4 object-contain"
            />
            <p className="w-43 max-w-43 font-normal text-gray-4 font-poppins text-sm group-hover:text-gray-5">
                {text}
            </p>
        </div>
    );
};

const onLogout = async () => {
    await signOut();
    localStorage.clear();
    window.location.href = "/";
};

const Menu = () => {
    const { push } = useRouter();

    const { userName } = useCurrentUser();

    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <Image
                    className="cursor-pointer rounded-full"
                    src={HamburgerMenuImg}
                    width={32}
                    height={32}
                    alt="menu-icon"
                    data-testid={"menu-icon"}
                />
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content
                    onOpenAutoFocus={(e) => e.preventDefault()}
                    onCloseAutoFocus={(e) => e.preventDefault()}
                    align="start"
                    side="top"
                    className="flex h-fit w-fit flex-col items-start space-y-4 rounded-md bg-yankees-blue px-4 py-5"
                >
                    <div className="flex items-center justify-start gap-x-3 px-0 py-1">
                        <Image
                            className="rounded-full"
                            src={DefaultImg}
                            width={32}
                            height={32}
                            alt="profile-picture"
                            data-testid={"profile-img"}
                        />
                        <div className="flex flex-col items-start gap-y-2">
                            <span className="w-40 max-w-40 truncate font-medium text-gray-4 trim-both font-poppins text-base">
                                {userName}
                            </span>
                            <EditName>
                                <span className=" flex cursor-pointer items-center font-normal text-dark-blue font-poppins text-xs">
                                    CHANGE
                                    <Image
                                        src={editImg}
                                        alt="edit-icon"
                                        width={20}
                                        height={20}
                                        draggable={false}
                                        className="ml-1 h-3 w-3 object-contain"
                                    />
                                </span>
                            </EditName>
                        </div>
                    </div>
                    <div className="my-4 h-[0.5px] w-full bg-[#6666C0]" />
                    <SubMenu
                        text={"My Balance"}
                        onClick={() => push("/wallet")}
                        img={WalletImg}
                    />
                    <HowToPlay>
                        <SubMenu text={"How to Play"} img={HowToPlayImg} />
                    </HowToPlay>
                    <SubMenu
                        text={"Terms & Conditions"}
                        onClick={() => push("/terms-and-conditions")}
                        img={TcImg}
                    />
                    <SubMenu
                        text={"Logout"}
                        onClick={onLogout}
                        img={LogoutImg}
                    />
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};

export default Menu;
