const DEFAULT_POPUP_DATA = {
    STEPS: [
        {
            id: 1,
            title: "Win points for every capture",
            subtitle: "Player with maximum points wins",
        },
        {
            id: 2,
            title: "You get ONLY 6 moves",
            subtitle: "Goal is to score maximum points",
        },
        {
            id: 3,
            title: "30 seconds for every move",
            subtitle: "All the best!",
        },
    ],
    POINTS: [
        {
            id: 1,
            piece: "Checkmate",
            point: "Instant win",
        },
        {
            id: 2,
            piece: "Queen",
            point: "9 points",
        },
        {
            id: 3,
            piece: "Rook",
            point: "5 points",
        },
        {
            id: 4,
            piece: "Bishop",
            point: "3 points",
        },
        {
            id: 5,
            piece: "Knight",
            point: "3 points",
        },
        {
            id: 6,
            piece: "Pawn",
            point: "1 point",
        },
    ],
};

const TOURNAMENT_POPUP_DATA = {
    STEPS: [
        {
            id: 1,
            title: "Rise as high as possible",
            subtitle: "Cross each level of increasing difficulty",
        },
        {
            id: 2,
            title: "Use your re-entries",
            subtitle: "If you lose, you can renter from a level below",
        },
        {
            id: 3,
            title: "Win cash prizes",
            subtitle: "Reach the highest levels to win cash prizes",
        },
    ],
    POINTS: [
        {
            id: 1,
            piece: "1st",
            point: "₹ 200",
        },
        {
            id: 2,
            piece: "2nd",
            point: "₹ 100",
        },
        {
            id: 3,
            piece: "3rd",
            point: "₹ 75",
        },
       /*  {
            id: 4,
            piece: "4th",
            point: "₹ 50",
        },
        {
            id: 5,
            piece: "5th",
            point: "₹ 40",
        },
        {
            id: 6,
            piece: "6th",
            point: "₹ 30",
        },
        {
            id: 7,
            piece: "7th",
            point: "₹ 20",
        },
        {
            id: 8,
            piece: "8th",
            point: "₹ 10",
        },
        {
            id: 9,
            piece: "9th",
            point: "₹ 5",
        },
        {
            id: 10,
            piece: "10th",
            point: "₹ 2",
        }, */
    ],
};

export { DEFAULT_POPUP_DATA, TOURNAMENT_POPUP_DATA };
