import BlackBishopImg from "@/assets/images/chess/black/bB.svg";
import BlackKingImg from "@/assets/images/chess/black/bK.svg";
import BlackKnightImg from "@/assets/images/chess/black/bN.svg";
import BlackPawnImg from "@/assets/images/chess/black/bP.svg";
import BlackQueenImg from "@/assets/images/chess/black/bQ.svg";
import BlackRookImg from "@/assets/images/chess/black/bR.svg";
import WhiteBishopImg from "@/assets/images/chess/white/wB.svg";
import WhiteKingImg from "@/assets/images/chess/white/wK.svg";
import WhiteKnightImg from "@/assets/images/chess/white/wN.svg";
import WhitePawnImg from "@/assets/images/chess/white/wP.svg";
import WhiteQueenImg from "@/assets/images/chess/white/wQ.svg";
import WhiteRookImg from "@/assets/images/chess/white/wR.svg";
import { StaticImageData } from "next/image";

export type PieceKey =
    | "bishop"
    | "king"
    | "queen"
    | "knight"
    | "pawn"
    | "rook"
    | string;
type ColorKey = "black" | "white" | string;

type PieceImage = {
    [key in ColorKey]: StaticImageData;
};

type PieceImages = {
    [key in PieceKey]: PieceImage;
};

const CHESS_PIECE_ICONS: PieceImages = {
    bishop: {
        black: BlackBishopImg,
        white: WhiteBishopImg,
    },
    king: {
        black: BlackKingImg,
        white: WhiteKingImg,
    },
    queen: {
        black: BlackQueenImg,
        white: WhiteQueenImg,
    },
    knight: {
        black: BlackKnightImg,
        white: WhiteKnightImg,
    },
    pawn: {
        black: BlackPawnImg,
        white: WhitePawnImg,
    },
    rook: {
        black: BlackRookImg,
        white: WhiteRookImg,
    },
};

type PiecesType = {
    [key: string]: string;
};

const CUSTOM_PIECES: PiecesType = {
    wP: "bg-wP",
    wN: "bg-wN",
    wB: "bg-wB",
    wR: "bg-wR",
    wQ: "bg-wQ",
    wK: "bg-wK",
    bP: "bg-bP",
    bN: "bg-bN",
    bB: "bg-bB",
    bR: "bg-bR",
    bQ: "bg-bQ",
    bK: "bg-bK",
};

export { CUSTOM_PIECES, CHESS_PIECE_ICONS };
