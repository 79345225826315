import React from "react";
import ArrowLeftImg from "@/assets/images/icons/arrow-back.png";
import Image, { StaticImageData } from "next/image";
import { useRouter } from "next/router";

const Back = ({
    imgParentDivClassName = "",
    isPrev = false,
    src = ArrowLeftImg,
    isRequiredDefaultStyle = true,
    dataTestID = "back-button",
    onClick,
}: {
    imgParentDivClassName?: string;
    isPrev?: boolean;
    src?: StaticImageData;
    isRequiredDefaultStyle?: boolean;
    dataTestID?: string;
    onClick?: (fallback: () => void) => void;
}) => {
    const { push, back } = useRouter();

    const fallback = () => {
        if (window.history && window.history.length > 2 && isPrev) {
            back();

            return;
        }

        push("/");
    };

    return (
        <div
            className={`${
                isRequiredDefaultStyle
                    ? "mx-auto mt-4 w-full max-w-95/100 bg-transparent"
                    : ""
            } ${imgParentDivClassName}`}
        >
            <Image
                alt="arrow-left"
                onClick={() => {
                    if (onClick) {
                        onClick(fallback);
                    } else {
                        fallback();
                    }
                }}
                src={src}
                height={28}
                width={28}
                className="cursor-pointer"
                data-testid={dataTestID}
            />
        </div>
    );
};

export default Back;
