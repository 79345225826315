//@ts-nocheck
/* @ts-nocheck eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import * as Types from '../../../types/generated-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/utils/apolloReactHooks';
const defaultOptions = {} as const;
export type EndSinglePlayerGameRoomWithWinner__Admin_ActionMutationVariables = Types.Exact<{
  roomID: Types.Scalars['uuid'];
  winner: Types.Scalars['String'];
  isDraw: Types.Scalars['Boolean'];
  isCheckmate: Types.Scalars['Boolean'];
}>;


export type EndSinglePlayerGameRoomWithWinner__Admin_ActionMutation = { __typename?: 'Mutation', endSinglePlayerGameRoomWithWinner: { __typename?: 'GenericResponse', success: boolean, message: string } };


export const EndSinglePlayerGameRoomWithWinner__Admin_ActionDocument = gql`
    mutation endSinglePlayerGameRoomWithWinner__Admin_Action($roomID: uuid!, $winner: String!, $isDraw: Boolean!, $isCheckmate: Boolean!) {
  endSinglePlayerGameRoomWithWinner(
    roomID: $roomID
    winner: $winner
    is_draw: $isDraw
    is_checkmate: $isCheckmate
  ) {
    success
    message
  }
}
    `;
export type EndSinglePlayerGameRoomWithWinner__Admin_ActionMutationFn = Apollo.MutationFunction<EndSinglePlayerGameRoomWithWinner__Admin_ActionMutation, EndSinglePlayerGameRoomWithWinner__Admin_ActionMutationVariables>;

/**
 * __useEndSinglePlayerGameRoomWithWinner__Admin_ActionMutation__
 *
 * To run a mutation, you first call `useEndSinglePlayerGameRoomWithWinner__Admin_ActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndSinglePlayerGameRoomWithWinner__Admin_ActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endSinglePlayerGameRoomWithWinnerAdminActionMutation, { data, loading, error }] = useEndSinglePlayerGameRoomWithWinner__Admin_ActionMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *      winner: // value for 'winner'
 *      isDraw: // value for 'isDraw'
 *      isCheckmate: // value for 'isCheckmate'
 *   },
 * });
 */
export function useEndSinglePlayerGameRoomWithWinner__Admin_ActionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EndSinglePlayerGameRoomWithWinner__Admin_ActionMutation, EndSinglePlayerGameRoomWithWinner__Admin_ActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EndSinglePlayerGameRoomWithWinner__Admin_ActionMutation, EndSinglePlayerGameRoomWithWinner__Admin_ActionMutationVariables>(EndSinglePlayerGameRoomWithWinner__Admin_ActionDocument, options);
      }
export type EndSinglePlayerGameRoomWithWinner__Admin_ActionMutationHookResult = ReturnType<typeof useEndSinglePlayerGameRoomWithWinner__Admin_ActionMutation>;
export type EndSinglePlayerGameRoomWithWinner__Admin_ActionMutationResult = Apollo.MutationResult<EndSinglePlayerGameRoomWithWinner__Admin_ActionMutation>;
export type EndSinglePlayerGameRoomWithWinner__Admin_ActionMutationOptions = Apollo.BaseMutationOptions<EndSinglePlayerGameRoomWithWinner__Admin_ActionMutation, EndSinglePlayerGameRoomWithWinner__Admin_ActionMutationVariables>;