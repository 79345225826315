import React, { useState, Fragment } from "react";
import BottomDialog from "@/components/BottomDialog";
import { toast } from "@/utils";
import { useUpdateNameMutation } from "@/contexts/currentUser/operations/operations.generated";

const EditName = ({ children }: { children: React.ReactNode }) => {
    const [isOpen, setIsOpen] = useState(false);

    const [name, setName] = useState("");

    // Toggle Popup

    const handlePopup = () => {
        setIsOpen((prev) => !prev);
    };

    const [updateName] = useUpdateNameMutation({
        onCompleted(data) {
            if (
                data?.update_supertokens_user_additional_fields?.returning?.[0]
                    ?.name
            ) {
                toast({
                    type: "success",
                    message:
                        "Name successfully updated to " +
                        data?.update_supertokens_user_additional_fields
                            ?.returning?.[0]?.name,
                });
                setName("");
                setIsOpen(false);
            }
        },
        onError(error) {
            console.log(error);
        },
    });

    return (
        <Fragment>
            <div
                className="w-full"
                data-testid={"edit-btn"}
                onClick={handlePopup}
            >
                {children}
            </div>
            <BottomDialog
                open={isOpen}
                setIsOpen={setIsOpen}
                onClose={handlePopup}
                dialogContentClassName={"!px-5 !pt-8 !pb-15"}
            >
                <div className="flex flex-col">
                    <p className="mt-4 font-normal text-gray-4 font-poppins text-sm">
                        What would you like to be known as?
                    </p>
                    <input
                        type="text"
                        placeholder="Enter your name"
                        className="font-15 my-5 w-full border-b border-dark-blue-24 bg-dark-blue-30 px-4 py-2 font-medium text-gray-4 placeholder-gray-9 outline-none placeholder:font-medium"
                        value={name}
                        name="name"
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />
                    <div className="mt-5 flex w-full flex-col items-center justify-center gap-y-4">
                        <button
                            className={`update-name-btn h-10 w-full cursor-pointer rounded-md bg-dark-pink-1 text-center font-extrabold text-gray-4 font-basement-grotesque text-sm ${
                                name.length <= 2 ? " opacity-40" : ""
                            } `}
                            type="submit"
                            onClick={() => {
                                if (name.length <= 2) {
                                    toast({
                                        type: "error",
                                        message: "Please enter a valid name",
                                    });

                                    return;
                                }
                                updateName({
                                    variables: {
                                        name,
                                    },
                                });
                            }}
                        >
                            Update
                        </button>
                    </div>
                </div>
            </BottomDialog>
        </Fragment>
    );
};

export default EditName;
