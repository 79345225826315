import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useRouter } from "next/router";

type Props = {
    title: React.ReactNode;
};

const TopBar = ({ title }: Props) => {
    const { back, push } = useRouter();

    return (
        <div className="sticky top-0 z-[999] flex h-12 w-full items-center justify-between bg-dark-blue-2">
            <div className="flex w-full items-center">
                <div className="h-full w-10 items-center justify-center">
                    <IoIosArrowBack
                        className="h-6 w-full cursor-pointer text-gray-5"
                        onClick={() => {
                            if (window.history && window.history.length === 0)
                                back();
                            else push("/");

                            return;
                        }}
                    />
                </div>
                {title}
            </div>
        </div>
    );
};

export default TopBar;
