import React, { Fragment, useState } from "react";
import { IoIosAdd } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import { useCurrentUser } from "@/contexts/currentUser";
import * as Popover from "@radix-ui/react-popover";
import { useEndSinglePlayerGameRoomWithWinner__Admin_ActionMutation } from "./operations/operations.generated";
import { toast } from "@/utils";

const index = ({ roomID }: { roomID?: string }) => {
    const { isAdmin } = useCurrentUser();

    const OPTIONS = ["win", "lose", "checkmate", "draw"];

    const [showDropdown, setShowDropdown] = useState(false);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const [endSinglePlayerGameRoomWithWinner] =
        useEndSinglePlayerGameRoomWithWinner__Admin_ActionMutation({
            onCompleted: (data) => {
                if (data?.endSinglePlayerGameRoomWithWinner?.success)
                    toggleDropdown();
            },
        });

    if (!isAdmin) return <Fragment />;

    return (
        <div className="relative w-full">
            <div className="absolute right-4 top-1 z-[99] w-fit">
                <Popover.Root>
                    <Popover.Trigger asChild>
                        <button
                            className="rounded-full bg-blue-500 p-3 font-semibold text-gray-4 shadow-md hover:bg-blue-600"
                            onClick={toggleDropdown}
                        >
                            {showDropdown ? (
                                <AiOutlineClose size={24} />
                            ) : (
                                <IoIosAdd size={24} />
                            )}
                        </button>
                    </Popover.Trigger>
                    <Popover.Portal>
                        <Popover.Content
                            onOpenAutoFocus={(e) => e.preventDefault()}
                            onCloseAutoFocus={(e) => e.preventDefault()}
                            align="end"
                            side="top"
                            className="z-[999] flex h-fit w-fit flex-col items-start justify-start space-y-4 rounded-md bg-yankees-blue p-3 transition-all duration-200 ease-linear"
                        >
                            {OPTIONS.map((data, index) => (
                                <div
                                    key={index}
                                    onClick={() => {
                                        endSinglePlayerGameRoomWithWinner({
                                            variables: {
                                                roomID,
                                                winner:
                                                    data === "lose"
                                                        ? "bot"
                                                        : "player",
                                                isDraw: data === "draw",
                                                isCheckmate:
                                                    data === "checkmate",
                                            },
                                        });
                                    }}
                                    className="cursor-pointer p-2 text-left text-gray-4 text-sm"
                                >
                                    End game with {data}
                                </div>
                            ))}
                            <div
                                onClick={() => {
                                    const formattedText =
                                        roomID?.toString() ?? "";

                                    if (window.ReactNativeWebView) {
                                        window.ReactNativeWebView.postMessage(
                                            JSON.stringify({
                                                event: "copy-to-clipboard",
                                                payload: formattedText,
                                            })
                                        );
                                    } else {
                                        navigator.clipboard.writeText(
                                            formattedText
                                        );
                                    }

                                    toast({
                                        type: "info",
                                        message: "Copied to clipboard",
                                    });

                                    toggleDropdown();
                                }}
                                className="cursor-pointer p-2 text-left text-gray-4 text-sm"
                            >
                                Copy room ID
                            </div>
                        </Popover.Content>
                    </Popover.Portal>
                </Popover.Root>
            </div>
        </div>
    );
};

export default index;
